/**
 * 此处可直接引用自己项目封装好的 axios 配合后端联调
 */
 import { postRequestCaptcha } from "@/libs/axios";
// import request from "@/api/axios.js"       //调用项目封装的axios

// 获取验证图片  以及token
export function reqGet(data) {
  return postRequestCaptcha( '/yethan/captcha/get', data );
}

// 滑动或者点选验证
export function reqCheck(data) {
  return postRequestCaptcha( '/yethan/captcha/check', data );
}

